import axios from 'axios'
import type {
  ContractPrice,
  ContractType,
  ContractTypeFlat,
  ContractExclusion,
  ContractPriceFlat,
} from '@/types/contractType'

export default class {
  static async getContracts({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<ContractType[]>('contracts', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getContractById(id: string) {
    return axios.get<ContractType>(`contracts/${id}`)
  }

  static async postContract(contract: ContractTypeFlat) {
    return axios.post<ContractType>(`contracts`, contract)
  }

  static async putContract(contract: ContractTypeFlat) {
    return axios.put<ContractType>(`contracts/${contract.id}`, contract)
  }

  static async getContractExclusionById(
    contractId: string,
    contractExclusionId: string,
  ) {
    return axios.get<ContractExclusion>(
      `/contracts/${contractId}/exclusions/${contractExclusionId}`,
    )
  }

  static async postContractExclusion(
    contractId: string,
    exclusion: Omit<ContractExclusion, 'id'>,
  ) {
    return axios.post<ContractExclusion>(
      `/contracts/${contractId}/exclusions`,
      exclusion,
    )
  }

  static async putContractExclusion(
    contractId: string,
    exclusion: ContractExclusion,
  ) {
    return axios.put<ContractExclusion>(
      `/contracts/${contractId}/exclusions/${exclusion.id}`,
      exclusion,
    )
  }

  static async deleteContractExclusion(
    contractId: string,
    contractExclusionId: string,
  ) {
    return axios.delete(
      `/contracts/${contractId}/exclusions/${contractExclusionId}`,
    )
  }

  static async getPricesByContractId(contractId: string) {
    return axios.get<ContractPrice[]>(`contracts/${contractId}/prices`)
  }

  static async postContractPrice(
    contractId: string,
    contractPriceObj: ContractPriceFlat,
  ) {
    return axios.post<ContractPrice>(`contracts/${contractId}/prices`, {
      ...contractPriceObj,
      contractPrice: contractPriceObj.contractPrice
        ? contractPriceObj.contractPrice * 100
        : undefined,
    })
  }

  static async putContractPrice(
    contractId: string,
    contractPriceObj: ContractPriceFlat,
  ) {
    return axios.put<ContractPrice>(
      `contracts/${contractId}/prices/${contractPriceObj.contractPriceId}`,
      {
        ...contractPriceObj,
        contractPrice: contractPriceObj.contractPrice
          ? contractPriceObj.contractPrice * 100
          : undefined,
      },
    )
  }

  static async deleteContractPrice(
    contractId: string,
    contractPriceId: string,
  ) {
    return axios.delete(`contracts/${contractId}/prices/${contractPriceId}`)
  }
}
