// Vue, WaimoUI and other base modules
import { createApp } from 'vue'
import dlpUi, {
  WaimoNotificationBadge,
  WaimoDialogService,
  WaimoToastService,
} from '@finetic/waimo-ui'
import '@finetic/waimo-ui/dist/index.css'
import App from '@/App.vue'
import i18n from '@/i18n'
import initializeRouter from '@/router'
import { createPinia } from 'pinia'
import { getMonth } from 'date-fns'
import { useUserStore } from '@/stores/users'
import initAxios from '@/axios.config'
import '@/scss/main.scss'
import initMercureSubscriptions from '@/utils/mercure'

// NProgress - Loading bar on route switch
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// Sentry - Error reporting
import * as Sentry from '@sentry/vue'

// Keycloak - Authentication
import Keycloak from 'keycloak-js'

if (getMonth(new Date()) === 11) {
  import('@/scss/xmas.scss')
}

NProgress.configure({
  showSpinner: false,
  speed: 400,
})

const app = createApp(App)

const router = initializeRouter()

app.use(createPinia())
app.use(dlpUi)
app.use(i18n)
app.use(WaimoDialogService)
app.use(WaimoToastService)

app.directive('WaimoNotificationBadge', WaimoNotificationBadge)

const keycloak = new Keycloak({
  realm: import.meta.env.VITE_SSO_REALM,
  // url: process.env.SSO_BASE_URL,
  url: import.meta.env.VITE_SSO_BASE_URL,
  clientId: import.meta.env.VITE_SSO_CLIENT_ID,
})

keycloak.onTokenExpired = async () => {
  try {
    await keycloak.updateToken()
  } catch (error) {
    console.error('Failed to refresh token:', error)
  }
}

keycloak.onAuthRefreshSuccess = () => useUserStore().fetchMe()

keycloak.onReady = async (authenticated: boolean) => {
  if (authenticated) {
    app.config.globalProperties.$keycloak = keycloak

    initAxios(app)

    await useUserStore().fetchMe()

    if (import.meta.env.PROD) {
      Sentry.init({
        app,
        dsn: 'https://cbc05bdc1c879d40ad13befaba2df905@o409719.ingest.us.sentry.io/4507961946996736',
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration(),
        ],

        // Tracing
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0, //  Capture 100% of the transactions

        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // Uncomment below to enable on dev env
          // /^(?!sso\.)dlp\.ddev\.site(.+)?/,
          /^(?!sso\.)dlp-acc\.finetic\.dev(.+)?/,
        ],

        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }

    app.use(router)
    app.mount('#app')

    initMercureSubscriptions()
  }
}

await keycloak
  .init({
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
  })
  .catch((error) => {
    console.error('Failed to initialize adapter:', error)
  })

export default app
