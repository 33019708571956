import axios from 'axios'
import { add, sub } from 'date-fns'
import type {
  AllocationType,
  AllocationDashboardData,
  AllocationDTO,
  AllocationPut,
  AllocationRevoke,
} from '@/types/allocationType'

export default class {
  static async getAllocations({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<AllocationType[]>('allocations', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getAllocationById(id: string) {
    return axios.get<AllocationType>(`allocations/${id}`)
  }

  static async postAllocation(
    municipalityId: string,
    allocations: AllocationDTO,
  ) {
    return axios.post<null>(
      `municipalities/${municipalityId}/allocations`,
      allocations,
    )
  }

  static async putAllocation(allocation: AllocationPut, isSupplier: boolean) {
    return axios.put<AllocationType>(
      `allocations/${allocation.id}${isSupplier ? '/supplier' : ''}`,
      allocation,
    )
  }

  static async revokeAllocation(allocation: AllocationRevoke) {
    return axios.put<AllocationType>(`allocations/${allocation.id}`, allocation)
  }

  static async getAllocationDashboardDataById(id: string) {
    return axios.get<AllocationDashboardData>(`allocations/${id}/dashboard`, {
      params: {
        since: sub(new Date(), { months: 22 }),
        until: add(new Date(), { months: 3 }),
      },
    })
  }
}
