import type { ClaimType } from '@/types/claimType'
import type { ClientType } from '@/types/clientType'
import type { AllocationType } from '@/types/allocationType'
import type { ProductType, ProductPrice } from '@/types/productType'
import type { SupplierType } from '@/types/supplierType'

export type FulfillmentType = {
  id: string
  claim: Pick<ClaimType, 'id' | 'claimNumber'>
  referenceNumber: string
  amount: number
  amountType: AmountType
  fulfillmentStatus: FulfillmentStatus
  floatingStatus?: {
    id: FulfillmentType['id']
    floatingStatus: FulfillmentFloatingStatus
    explanation: string // Explanation given by user on allocation date change that results in a floating fulfillment
    acceptedExplanation?: string // Explanation that is required when setting `floatingStatus.floatingStatus` to ACCEPTED
  }
  since: string
  until: string
  supplier: Pick<SupplierType, 'id' | 'name'>
  client: Pick<ClientType, 'id'> & { name: string }
  allocation: Pick<AllocationType, 'id'>
  product: Pick<ProductType, 'id' | 'name' | 'wmoCode'>
  user?: any // TODO: Define
  waitingOnFulfillment?: FulfillmentType['id']
  relatedCredit?: FulfillmentType['id']
  relatedDebit?: FulfillmentType['id']
  tariff: ProductPrice['price']
  amountAwarded: number
  pendingReason?: FulfillmentPendingReason
  rejectReasons?: Array<FulfillmentRejectReason>
  comment?: string
  allocationNumber?: AllocationType['allocationNumber']
  bsn: ClientType['bsn']
  productCategory: string
  productWmoCode: ProductType['wmoCode']
  volume: number
  unit: ProductType['unit']
  dateAwarded?: string
  datePaid?: string
  processLog: FulfillmentProcessLog
}

export type FloatingFulfillmentPut = Omit<
  NonNullable<FulfillmentType['floatingStatus']>,
  'explanation'
>

export type FulfillmentProcessLog = Record<
  string,
  Array<{
    message: string
    compareValue1: string | null | undefined
    compareValue2: string | null | undefined
    result: 'true' | 'false'
    status?: string | null
    oldStatus?: string | null
    type?: 'result' | 'check'
    executionTime?: string
  }>
>

export enum AmountType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum FulfillmentStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_MANUAL_CHECK = 'pending_manual_check',
  REJECTED = 'rejected',
  WAITING = 'waiting',
}

export enum FulfillmentFloatingStatus {
  FLOATING = 'floating',
  ACCEPTED = 'accepted',
  CREDIT_REQUIRED = 'credit_required',
}

export enum FulfillmentOptions {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

export enum FulfillmentRejectReason {
  INVOICE_NUMBER_INVALID = 'invoice_number_invalid',
  CLIENT_BSN_INVALID = 'client_bsn_invalid',
  CLIENT_BIRTH_DATE_INVALID = 'client_birth_date_invalid',
  SERVICE_PROVIDER_CODE_INVALID = 'service_provider_code_invalid',
  MUNICIPALITY_INVALID = 'municipality_invalid',
  PRODUCT_CODE_MISMATCH = 'product_code_mismatch',
  TIME_UNIT_MISMATCH = 'time_unit_mismatch',
  PERFORMANCE_DATE_OUTSIDE_ALLOCATION = 'performance_date_outside_allocation',
  END_DATE_BEFORE_START_DATE = 'end_date_before_start_date',
  START_DATE_BEFORE_SYSTEM_DATE = 'start_date_before_system_date',
  VAT_TARIFF_MISMATCH = 'vat_tariff_mismatch',
  REFERENCE_NUMBER_INVALID = 'reference_number_invalid',
  AUTHORIZATION_MISSING = 'authorization_missing',
  BUDGET_CALCULATION_ERROR = 'budget_calculation_error',
  DECISION_NUMBER_MISSING = 'decision_number_missing',
  SERVICE_PROVIDER_AGB_CODE_INVALID = 'service_provider_agb_code_invalid',
  TECHNICAL_REJECTION = 'technical_rejection',
  PERFORMANCE_PERIOD_OUTSIDE_INVOICE_PERIOD = 'performance_period_outside_invoice_period',
  PERFORMANCE_END_DATE_OUTSIDE_ALLOCATION = 'performance_end_date_outside_allocation',
  PRODUCT_CODE_INVALID = 'product_code_invalid',
  TARIFF_MISMATCH = 'tariff_mismatch',
  MISSING_CREDIT_REFERENCE = 'missing_credit_reference',
  CREDIT_WITHOUT_DEBIT = 'credit_without_debit',
  DUPLICATE_DECLARATION = 'duplicate_declaration',
  DUPLICATE_CREDIT = 'duplicate_credit',
  INCORRECT_MESSAGE_VERSION = 'incorrect_message_version',
  CLAIM_NUMBER_NOT_UNIQUE = 'claim_number_not_unique',
  DIFFERENCE_IN_CALCULATED_CLAIM_AMOUNT = 'difference_in_calculated_claim_amount',
  CLAIM_ON_DELETED_ALLCATION = 'claim_on_deleted_allcation',
  INVALID_START_DATE_PRODUCT_PERIOD = 'invalid_start_date_product_period',
  INVALID_END_DATE_PRODUCT_PERIOD = 'invalid_end_date_product_period',
  OVERSPENDING = 'overspending',
  LATE_SUBMISSION = 'late_submission',
  BLOCKED_SUPPLIER = 'blocked_supplier',
  OUTSIDE_BANDWIDTH = 'outside_bandwidth',
  YEAR_END_CROSSING = 'year_end_crossing',
  TOTAL_OVERSPENDING = 'total_overspending',
}

export enum FulfillmentPendingReason {
  OVERSPENDING = 'overspending',
  LATE_SUBMISSION = 'late_submission',
  BLOCKED_SUPPLIER = 'blocked_supplier',
  OUTSIDE_BANDWIDTH = 'outside_bandwidth',
  YEAR_END_CROSSING = 'year_end_crossing',
  TOTAL_OVERSPENDING = 'total_overspending',
}
