import { defineStore } from 'pinia'
import type {
  AllocationType,
  AllocationDTO,
  AllocationPut,
  AllocationRevoke,
} from '@/types/allocationType'
import type { SortBy } from '@/types/types'
import AllocationService from '@/services/AllocationService'

export const useAllocationStore = defineStore({
  id: 'allocations',
  state: (): {
    data: Map<
      string,
      AllocationType & { timestamp?: number, outdated?: boolean }
    >
    page: number
    filters?: Record<string, any>
    sortBy?: Record<string, 'ASC' | 'DESC'>

    productCustomFields?: AllocationType['productCustomFields']
    allocationParams?: string
  } => ({
    data: new Map(),
    page: 1,
    filters: {},
    sortBy: {
      allocationNumber: 'DESC',
    },

    productCustomFields: undefined,
    allocationParams: undefined,
  }),
  actions: {
    async fetchNextPage(clear?: boolean) {
      if (clear) this.page = 1

      return AllocationService.getAllocations({
        page: this.page++,
        filters: this.filters,
        order: this.sortBy,
      })
        .then((res) => {
          if (clear) this.data.clear()
          return res
        })
        .then(({ data }) => data.forEach((x) => this.data.set(x.id, x)))
    },

    async setFilters(filters: Record<string, any> = {}) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.filters = { ...filters }

        await this.fetchNextPage(true)
      }
    },

    async setSorting(sortBy?: SortBy) {
      if (sortBy !== this.sortBy) {
        this.sortBy = sortBy ? { [sortBy.field]: sortBy.direction } : undefined

        await this.fetchNextPage(true)
      }
    },

    async addNewAllocation(municipalityId: string, allocations: AllocationDTO) {
      return AllocationService.postAllocation(municipalityId, allocations)
    },

    async updateAllocation(allocation: AllocationPut, isSupplier: boolean) {
      const { data } = await AllocationService.putAllocation(
        {
          ...allocation,
          productCustomFields: allocation.productCustomFields?.filter(
            (x) => x.value,
          ), // remove productCustomFields objects without a value
        },
        isSupplier,
      )

      this.data.set(allocation.id, data)

      return data
    },

    async revokeAllocation(allocation: AllocationRevoke) {
      const { data } = await AllocationService.revokeAllocation(allocation)

      this.data.set(allocation.id, data)

      return data
    },

    async fetchAllocationById(id: string, cached = true) {
      const local = this.data.get(id)

      if (
        !cached ||
        !local ||
        !(local?.timestamp ?? 0 + 3600000 >= new Date().getTime()) ||
        local?.outdated
      ) {
        const { data } = await AllocationService.getAllocationById(id)

        this.data.set(id, {
          ...(local?.outdated ? local : {}),
          ...data,
          timestamp: new Date().getTime(),
          outdated: false,
        })

        return data
      }

      return local
    },

    async fetchAllocationDashboardDataById(id: string) {
      const { data } =
        await AllocationService.getAllocationDashboardDataById(id)

      const current = this.data.get(id)

      if (current)
        this.data.set(id, {
          ...current,
          dashboardData: data,
        })

      return data
    },

    async markAllocationAsOutdated(id: string, watching: boolean) {
      const local = this.data.get(id)

      if (local?.timestamp) {
        local.outdated = true

        if (watching) this.fetchAllocationById(id, false)
      }
    },
  },
  getters: {
    dataAsArray: (state) => Array.from<AllocationType>(state.data.values()),

    findOrThrowAllocationById: (state) => (id: string) => {
      const allocation = state.data.get(id)

      if (!allocation) throw new Error()

      return allocation
    },

    findOrThrowDashboardByAllocationId: (state) => {
      return (id: string) => {
        const dashboardData = state.data.get(id)?.dashboardData

        if (!dashboardData) throw new Error()

        return dashboardData
      }
    },
  },
})
