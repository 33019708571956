<template>
  <WaimoFlex
    justify="between"
    align="start"
    class="common-control-heading"
  >
    <WaimoFlex
      class="common-control-heading__left"
      align="center"
    >
      <WaimoIcon
        v-if="showBackButton"
        class="cursor-pointer p-l-1"
        :color="Colors.Primary"
        icon="/icons/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg"
        :size="Sizes.xl"
        @click="backLink ? $emit('back-link') : router.back()"
      />
      <slot name="left">
        <WaimoIcon
          v-if="icon"
          :type="IconType.Contained"
          :icon="icon"
          :size="Sizes.xl"
        />
        <div
          v-if="image"
          class="common-control-heading__image"
          :style="{ '--image': `url(${image})` }"
        />
      </slot>
      <WaimoFlex
        direction="column"
        gap="0.4rem"
      >
        <WaimoFlex
          align="center"
          :wrap="true"
        >
          <span class="common-control-heading__top sensitive">
            {{ topText }}
          </span>
          <slot name="top" />
          <span
            v-if="extraTopText"
            class="dark-text-40 sensitive"
          >
            ({{ extraTopText }})
          </span>
          <template
            v-for="(badge, i) in badges"
            :key="i"
          >
            <WaimoBadge
              v-if="badge"
              :color="badge.color"
              :text="badge.label"
            />
          </template>
        </WaimoFlex>
        <WaimoFlex
          v-if="subText || subIcons"
          class="common-control-heading__sub"
          align="center"
          :wrap="true"
        >
          <span
            v-if="subText"
            class="m-r-1.5 sensitive"
          >
            {{ subText }}
          </span>
          <template
            v-for="(subIcon, index) in subIcons"
            :key="index"
          >
            <WaimoFlex
              v-if="subIcon"
              direction="row"
              align="center"
            >
              <WaimoIcon
                :icon="subIcon.icon"
                :size="Sizes.md"
              />
              <span
                v-if="!subIcon.link"
                class="m-r-0.5 sensitive"
              >
                {{ subIcon.label }}
              </span>
              <router-link
                v-else
                class="common-control-heading__link"
                :to="subIcon.link"
              >
                <span class="m-r-0.5 sensitive">
                  {{ subIcon.label }}
                </span>
              </router-link>
            </WaimoFlex>
          </template>
        </WaimoFlex>
        <WaimoFlex
          v-if="subIconsLower"
          class="common-control-heading__sub"
          align="center"
        >
          <span
            v-if="subText"
            class="m-r-1.5 sensitive"
          >
            {{ subText }}
          </span>
          <template
            v-for="(subIcon, index) in subIconsLower"
            :key="index"
          >
            <template v-if="subIcon">
              <WaimoIcon
                :icon="subIcon.icon"
                :size="Sizes.md"
              />
              <span
                v-if="!subIcon.link"
                class="m-r-0.5 sensitive"
              >
                {{ subIcon.label }}
              </span>
              <router-link
                v-else
                class="common-control-heading__link"
                :to="subIcon.link"
              >
                <span class="m-r-0.5 sensitive">
                  {{ subIcon.label }}
                </span>
              </router-link>
            </template>
          </template>
        </WaimoFlex>
      </WaimoFlex>
    </WaimoFlex>
    <div class="common-control-heading__right">
      <slot name="right" />
    </div>
  </WaimoFlex>
</template>

<script setup lang="ts">
import {
  Colors,
  IconType,
  Sizes,
  WaimoBadge,
  WaimoFlex,
  WaimoIcon,
} from '@finetic/waimo-ui'
import { type RouteLocationRaw } from 'vue-router'
import { useRouter } from 'vue-router'

const router = useRouter()

withDefaults(
  defineProps<{
    showBackButton?: boolean
    backLink?: boolean
    icon?: string
    image?: string
    badges?: Array<{ color: Colors, label: string } | undefined>
    topText: string
    extraTopText?: string
    subText?: string
    subIcons?: Array<
      | {
        icon: string
        label: string
        link?: RouteLocationRaw
      }
      | undefined
    >
    subIconsLower?: Array<
      | {
        icon: string
        label: string
        link?: RouteLocationRaw
      }
      | undefined
    >
  }>(),
  {
    showBackButton: false,
    backLink: false,
    subText: undefined,
    extraTopText: undefined,
    icon: undefined,
    image: undefined,
    badges: undefined,
    subIcons: undefined,
    subIconsLower: undefined,
  },
)

defineEmits(['back-link'])
</script>

<style scoped lang="scss">
.common-control-heading {
  justify-content: space-between;
  align-items: start;
  padding: 2.4rem 2.4rem 1.2rem 2.4rem;
  margin-bottom: 1.2rem;
  z-index: 15;

  &__image {
    background-color: var(--color-light);
    background-image: var(--image);
    background-size: 2.4rem;
    background-position: center center;
    background-repeat: no-repeat;
    height: 4.8rem;
    aspect-ratio: 1;
    border: 1px solid var(--color-dark-20);
    border-radius: 50%;
  }

  &__left {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__top {
    font-weight: 600;
    margin: 0.8rem 0;
  }

  &__sub {
    color: var(--color-dark-60);
    font-size: 0.8em;
    font-weight: 500;

    :deep(svg) {
      color: var(--color-dark-60);
    }
  }

  &__link {
    color: var(--color-dark-60);
  }

  &__right {
    flex-shrink: 0;
  }
}
</style>
