import axios from 'axios'
import type { CreditorFlat, CreditorType } from '@/types/creditorType'

export default class {
  static async getCreditors({
    page = 1,
    pageSize = 30,
    filters = undefined,
    order = undefined,
  }: {
    page?: number
    pageSize?: number
    filters?: Record<string, any>
    order?: Record<string, 'ASC' | 'DESC'>
  } = {}) {
    return axios.get<CreditorType[]>('creditors', {
      params: { ...filters, order, page, itemsPerPage: pageSize },
    })
  }

  static async getCreditorById(id: string) {
    return axios.get<CreditorType>(`creditors/${id}`)
  }

  static async putCreditor(creditor: CreditorFlat) {
    return axios.put<CreditorType>(`creditors/${creditor.id}`, creditor)
  }

  static async postCreditor(creditor: CreditorFlat) {
    return axios.post<CreditorType>(`creditors`, creditor)
  }
}
